.modal {
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: rgba(33, 37, 41, 0.5);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 9000;

  &-dialog {
    position: relative;
    margin: 0.5rem;
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fefefe;
    background-clip: padding-box;
    border-radius: 0.5rem;
    outline: 0;
    padding: 1rem;
  }
}

.img-fluid {
  height: auto;
  width: 100%;
}

.promotion-message {
  margin-bottom: 2rem;
}

.promotion {
  text-align: center;
  margin-bottom: 1rem;
}

.playstore {
  height: auto;
  width: 11.5rem;
}

.appstore {
  height: auto;
  width: 10rem;
}
