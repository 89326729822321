.btn-inside-card-wrapper {
  display: flex;
  gap: 1rem;
  .btn-inside-card-left {
    width: 100%;
    border: 0;
    padding: 12px;
    background-color: #58b7ba;
    color: #fff;
    border-radius: 1rem;
    white-space: nowrap;
    display: inline-block;
    height: 10px;
    p {
      margin-top: -7px;
    }
  }
  .btn-inside-card-right {
    width: 100%;
    border: 2px solid #e6c4a0;
    padding: 12px;
    background-color: white;
    color: #e6c4a0;
    border-radius: 1rem;
    white-space: nowrap;
    display: inline-block;
    height: 10px;
    p {
      margin-top: -7px;
    }
  }
}
.btn-next-wrapper {
  display: flex;
  justify-content: center;

  &.add{
    position: fixed;
    bottom: 75px;
    left: 10px;
  }

  .btn-next {
    width: 100%;
    border: 0;
    padding: 12px;
    background-color: #1bb8ba;
    color: #fff;
    border-radius: 1rem;
    box-shadow: 0px 8px 11px -2px rgba(0, 0, 0, 0.3);
    height: 4rem;
  }
  p {
    font-size: 16px;
  }
}
.receipt-like-style {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid lightgray;
  p {
    margin: 0px;
    &.bold {
      font-weight: bold;
    }
  }
}
.sp {
  --incTrans: 0.1; /* in seconds */
  display: block;
  margin-bottom: 1.5em;
  overflow: visible;
  width: 100%;
  height: 100%;
  position: relative;
  left: 50px;
  //transform: translate(50px, 0px);
}
p {
  margin: auto;
}

$color1: #f4f4f4;
$color2: #1bb8ba;
.radio {
  margin: 0.5rem;
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 1rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    margin-top: 1%;
    + .radio-label {
      margin-bottom: 0px;
      line-height: 13px;
      &:before {
        content: "";
        background: $color1;
        border-radius: 100%;
        border: 2px solid $color2;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.btn {
  background-color: transparent;
  border-radius: 1em;
  display: block;
  position: relative;
  transition: width 0.3s ease-in-out;
}
.btn__text {
  background-color: #1bb8ba;
  border-radius: inherit;
  color: white;
  display: inline-block;
  padding: 0.75em 1.5em;
  width: 100%;
  font-size: 16px;
}
.btn__progress {
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
  width: 3em;
  height: 3em;
  visibility: hidden;
}
.btn__progress-track {
  r: 12px;
  stroke: hsl(223, 10%, 80%);
  stroke-width: 24;
}
.btn__progress-fill {
  stroke: #1bb8ba;
  stroke-dashoffset: 125.66;
}
.btn__progress-check {
  stroke: white;
  stroke-dashoffset: 34;
}

.btn-submit{
  width: 100%;
  border-radius: 5px;
  border: 0;
}

/* Both states */
.btn--running,
.btn--done {
  outline: none;
  pointer-events: none;
  width: 3em;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.btn--done .btn__text {
  visibility: hidden;
}
.btn--running .btn__text {
  color: transparent;
  transition: background-color 0.3s ease-in-out, visibility 0.3s steps(1);
}
.btn--running .btn__progress,
.btn--done .btn__progress {
  visibility: visible;
}

.btn--running .btn__text {
  background-color: hsl(223, 10%, 80%);
  visibility: hidden;
}
.card-wrapper {
  height: 170vh;
  overflow-y: scroll;
  padding: 0 0 15rem 0;
}
.card-visible {
  height: 120px;
  margin-bottom: 1rem;
  border-radius: 0px;
  position: relative;
}

.actual-content {
  display: flex;
  justify-content: space-around;
  border: 1px solid lightgrey;
  background: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 1rem;
}
.tab {
  width: 100%;
  color: black;
  overflow: hidden;
  margin-bottom: 1rem;
  &.active {
    background: #f7f7f7;
  }
  &-label {
    background: #f7f7f7;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    cursor: pointer;
    /* Icon */
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all 0.35s;
    }
  }
  &-content {
    max-height: 0;
    color: black;
    //background: #FFFFFF;
    transition: all 0.35s;
    border: none;
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 0.5rem;
    visibility: hidden;
  }
}
.checkbox-collapsible {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.checkbox-collapsible:checked {
  + .tab-label {
    background: darken(#f7f7f7, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    height: 100%;
    max-height: fit-content;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 1rem;
    //border: 1px solid lightgrey;
    visibility: visible;
  }
}
.installment-list:last-child {
  margin-bottom: 5rem;
}
.loan-form-container {
  display: flex;
  gap: 1rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  .radio-context {
    background: rgb(247, 247, 247);
    border: 2px solid rgb(27, 184, 186);
    border-radius: 5px;
    margin-bottom: 1rem;
  }
}

.bank-box{
  border: 1px solid #ddd;
  text-align: left;
  border-radius: 5px;
  padding: 10px;

  h4{
    margin: 0;
  }

  p{
    color: rgb(138, 138, 138);
  }
}
.mb-0{
  margin-bottom: 0;
}

.form-loan{
  padding-bottom: 10rem;
}

.p-4{
  padding: 2em;
}

.mb-2{
  margin-bottom: 1em;
}

.submit-btn {
  background-image: none;
  background-color: var(--theme-green);
  border: none;
  color: white;
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  text-align: center;

  &:disabled {
    background-color: #ddd;
  }
}

.btn-cancel{
  background-color: #db3737;
  padding: 8px 15px;
  border: 0;
  width: 100%;
  border-radius: 5px;
  color: #fff;
}

.bp3-button.confirm-btn{
  background-color: #00bcd4 !important;
  background-image: unset;
  color: #fff;
  margin-right: 1em;
}

.bp3-dialog.custom-modal-confirm{
  height: auto;
}

.btn-inside-card-right{
  &.badge-canceled{
    border-color: #f44336;
    color: #fff;
    background-color: #f44336;
  }

  &.badge-pending{
    background-color: #ffc107;
    color: #fff;
    border-color: #ffc107;
  }

  &.badge-approved{
    background-color: #1bb8ba;
    border-color: #1bb8ba;
    color: #fff;
  }
}

.form-group{
  text-align: left;

  .form-control{
    width: 100%;
  }
}