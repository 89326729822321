.presence-history {
  width: 100%;
  min-height: 90vh;
  position: relative;

  .tabs {
    position: fixed;
    width: 100%;
    padding-top: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      width: 49%;
      margin: auto;
      padding: 10px;
      text-align: center;
      border: none;
      border-bottom: 1px solid black;
      outline: none;
      background-color: #fff;

      &.active {
        background-color: hsl(0, 0%, 95%);
      }
    }
  }

  .list-view {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    padding-top: 20px;
  }
}