.cuti {
  width: 100%;
  min-height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;

  .cuti-list {
    .header-list {
      font-size: 1.2em;
      color: #000;
      margin: 20px 0;
    }

    .list-group {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: .9em;
      padding: 10px;
      background-color: #fff;
      box-shadow: hsl(0, 0%, 40%) 0px 0px 25px -15px;
      border-radius: 10px;
      margin-top: 10px;

      .left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;

        .btn {
          outline: none;
          border: none;
          border-radius: 8px;
          padding: 5px 10px;
          background-color: #b36161;
          color: #fff;
        }
      }
    }
  }

  .select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: var(--theme-light);
    font-family: Poppins;
    width: 100%;
  }

  .upload-photo {
    input {
      display: none;
    }
    .label {
      font-size: 12px;
      color: var(--theme-font-upload);
    }
    .file-box {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: gray 1px solid;
      padding: 5px;

      .text {
        font-size: 12px;
        margin: 0;
        color: var(--theme-font-upload);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .btn {
        outline: none;
        border: none;
        border-radius: 8px;
        padding: 5px 10px;
        background-color: var(--theme-green);
        color: #fff;
      }
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    background-color: var(--theme-light);
    font-family: Poppins;
    &::-webkit-input-placeholder {
      font-family: Poppins;
    }
  }

  .submit-btn {
    background-image: none;
    background-color: var(--theme-green);
    border: none;
    color: white;
    width: 100%;
    padding: 20px;
    margin-top: 10px;
    text-align: center;

    &:disabled {
      background-color: #ddd;
    }
  }

  .error {
    margin: 0;
    color: #c14040;
    font-size: 10px;
    font-style: italic;
  }
}
