.profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.profile-header {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
}

.profile-card {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
}

.profile-card .profile-card-header {
  font-size: 1.5rem;
  font-weight: bold;
}

.profile-card .profile-card-body {
  margin: 2rem 1rem 1rem;
}

.profile-card-button {
  width: 100%;
  background-image: unset !important;
  box-shadow: unset !important;
  padding: 10px;
}

.profile-card .profile-card-button:last-of-type {
  width: 100%;
  margin: 0;
}

.profile-card .profile-hr {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.profile-footer {
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
}

.profile-card .profile-text {
  font-size: 1.6rem;
}

.header-profile {
  width: 100%;
  height: 30vh;
  background: #3fc3c5;
  padding: 3rem 2em;
  color: #fff;
  text-align: center;
}

.img-profile {
  width: 80px;
  height: 80px;
  background-color: blue;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: auto;
}

.img-profile img {
  object-fit: cover;
  max-width: 100%;
  object-position: center;
}

.body-profile {
  background-color: #fff;
  width: 100%;
  height: auto;
  margin-top: -42px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  padding: 3rem 2rem;
}
.profile-page {
  color: #fff;
  background-color: #3fc3c5 !important;
}

.profile-page svg {
  fill: #fff;
}

.body-profile ul {
  list-style: none;
  padding: 0;
}

.body-profile ul small {
  display: block;
  margin-bottom: 4px;
}

.icon-info {
  width: auto !important;
}

.icon-info img {
  width: 36px;
  margin-right: 12px;
}

.body-profile ul li {
  margin-bottom: 2em;
}

.pt-1 {
  padding-top: 1em;
}
