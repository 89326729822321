.scan-qr {
  width: 100%;
  min-height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .camera-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .employee-section {
    margin-top: 20px;
    min-width: 80%;
    display: flex;
    flex-direction: column;
    border: 1px #ddd solid;
    border-radius: 8px;

    .employee-header {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;
      background-color: #83C3FF;
      border-radius: 8px 8px 0 0;
    }
  
    .employee-data {
      width: 100%;
      padding: 30px;
      height: 40px;
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      text-align: center;
  
      label {
        margin: 0;
      }
    }
  
  }
  .submit-presence {
    position: absolute;
    bottom: 80px;
    background-image: none;
    background-color: var(--theme-green);
    border: none;
    border-radius: 5px;
    box-shadow: none;
    color: white;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    text-align: center;
  }
}
