:root {
  --theme-green: rgb(82 181 184);
  --theme-light-green: rgb(213 237 211);
  --theme-red: rgb(235, 65, 106);
  --theme-pink: rgb(255, 123, 145);
  --theme-purple: rgb(133, 150, 220);
  --theme-gray: rgb(47 55 71);
  --theme-medium-gray: rgb(178 188 202);
  --theme-light-gray: rgb(238 242 246);
  --theme-plum: rgb(72 29 60);
  background-color: var(--theme-light-gray);
  color: var(--theme-gray);
}

html {
  font-size: 12px;
  line-height: 1.5;
  font-family: Poppins;
}

body {
  font-family: Poppins;
}

@media only screen and (min-width: 425px) {
  html {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1024px) {
  html {
    font-size: 20px;
    line-height: 1.5;
  }
}
