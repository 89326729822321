.request-page-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.request-page-container .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.request-page-container .hr {
  background-image: none;
  margin: 2% auto;
  width: 80vw;
}

hr {
  border-style: solid;
  color: var(--theme-light-gray);
}

.request-page-container .label {
  font-size: 1.2rem;
  color: var(--theme-medium-gray);
  text-transform: capitalize;
}

.request-page-container .amount {
  font-size: 2.2rem;
  color: var(--theme-purple);
}

.request-page-container .header {
}

.request-page-total-allowance .amount {
  flex-direction: row;
  display: flex;
  margin: 2% auto;
}

.request-page-total-allowance .amount .icon {
  color: var(--theme-gray);
}

.request-page-total-allowance .amount .icon {
  margin: auto;
}

.request-page-withdrawal-amount .amount {
  flex-direction: row;
  display: flex;
  margin: 0 auto;
}

.request-page-withdrawal-amount .bp3-slider {
  width: 60vw;
  height: 10vw;
}

.request-page-withdrawal-amount .bp3-slider-progress {
  background: var(--theme-purple);
}

.request-page-withdrawal-amount .bp3-slider-label {
  display: none;
}
.request-page-withdrawal-amount .input-field-amount {
  display: "flex";
  flex-direction: "row";
  justify-content: "center";
}
.request-page-withdrawal-amount .input-field-amount input {
  width: 100%;
  padding: 8px 10px;
  opacity: 1;
  line-height: 1.5;
  font-family: Poppins;
  font-size: 1.2rem;
}

.request-page-withdrawal-amount
  .input-field-amount
  input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
  width: 25px;
}

.request-page-container .container.submit-button {
  margin-bottom: 0% auto 20% auto;
}

.request-page-container .submit-button .bp3-button {
  font-size: 1.2rem;
  color: white;
  background-color: var(--theme-green);
  background-image: none;
  box-shadow: none;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  width: 100%;
}

.request-page-container .submit-button .bp3-button:disabled {
  background-color: var(--theme-medium-gray);
}

.container.youtube .bp3-button {
  background: none;
  color: var(--theme-green);
}

.banner {
  width: auto;
  max-width: 100%;
  height: auto;
}

.bp3-dialog {
  background-color: white;
  height: 70vh;
  display: flex;
  flex-direction: column;
  margin: 4%;
  padding: 4%;
}

.request-page-confirmation-container {
  font-size: 1.2rem;
  color: var(--theme-gray);
  margin: 1%;
  font-weight: bold;
}

.request-page-confirmation-container.body .dialog-hr {
  margin-top: 5%;
  margin-bottom: 5%;
}

.request-page-confirmation-container .confirmation-amount {
  font-size: 1.2rem;
}

.request-page-confirmation-container .subtitle {
  font-size: 1.2rem;
}

.request-page-confirmation-container .confirmation-fee {
  color: var(--theme-red);
}

.request-page-confirmation-container .confirmation-principal {
  color: var(--theme-green);
}

.request-page-confirmation-container.message-input {
  margin: 10% 0% 0;
}

.request-page-confirmation-container input {
  border: var(--theme-green) solid 0.01em;
  border-radius: 0.3em;
  width: 100%;
  padding: 2%;
  background-color: rgba(82, 181, 184, 0.07);
  margin: 2% 0%;
}

.request-page-confirmation-container.footer {
  text-align: right;
}

.request-page-confirmation-container.footer .bp3-button {
  margin: 2%;
  border-radius: 2em;
  box-shadow: none;
  background-image: none;
  font-family: Poppins;
}
.request-page-confirmation-container.footer .bp3-button.cancel {
  background-color: #ff6060;
  color: #fff;
  border-radius: 5px;
  margin-right: auto;
  margin-left: 0;
}

.request-page-confirmation-container.footer .bp3-button.confirm {
  background-color: var(--theme-green);
  color: white;
  border-radius: 5px;
  margin-right: 0;
}

.request-page-confirmation-container.footer .bp3-button.disabled {
  background-color: var(--theme-medium-gray);
  color: white;
  border-radius: 5px;
  margin-right: 0;
}

.form-control {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin: 1em 0;
}

.btn-info button {
  padding: 10px;
  background-color: #52b5b8 !important;
  background-image: unset !important;
  color: #fff !important;
}

.request-page-withdrawal-amount .input-field-amount {
  margin-top: 1em;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  background: #52b5b8;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-top: 1em;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #52b5b8;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: var(--theme-gray);
  cursor: pointer;
}

.btn.btn-close {
  background-color: #f83e3e !important;
  width: 100%;
  margin: 1em 0;
  border: 0;
  border-radius: 4px;
}

.cap-container {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
}

.font-black {
  color: black;
}

.font-red {
  color: rgb(252, 103, 103);
}

.approve-text {
  text-align: left;
  line-height: 1.2;
  margin-left: 10px;
}

.approve-text small {
  font-size: 10px;
}

.tnc-box {
  margin-top: 1em;
}

.box-checkbox {
  margin-top: 3px;
}

.tnc-checkbox {
  width: 20px !important;
  margin: 0 !important;
}

.text-tnc {
  overflow: scroll;
  height: 70vh;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.amount-text {
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 10px;
}

.amount-box {
  border: 2px solid #6cc3ff;
  padding: 10px;
  border-radius: 12px;
}

.font-weight-normal {
  font-weight: 500;
}

.amount-box hr {
  border: 2px solid #6cc3ff;
}

.request-reason {
  width: 100%;
  min-height: 50px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px;
  resize: none;
}

.request-reason.error {
  border-color: red;
}

.amount-choice-box {
  display: block;
}

.amount-choice-box label {
  display: block;
  margin-bottom: 0;
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px #ddd;
  margin-bottom: 1em;
  text-align: left;
  border: 1px solid transparent;
}

.amount-choice-box label input {
  margin-top: 4px;
  margin-right: 20px;
}

.amount-choice-box .amount-wrap {
  margin-top: 10px;
  display: flex;
}

.amount-choice-box .h3 {
  margin: 0;
}

.text-left {
  text-align: left;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: #ddd;
  width: 1.15em;
  height: 1.15em;
  border: 2px solid #ddd;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #38c4c6;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline: none;
}

label.selected {
  border: 1px solid #38c4c6 !important;
}

.disabled-card {
  background-color: #ededed !important;
  pointer-events: none;
}

.d-none {
  display: none !important;
}
