.history-page {
  width: 100vw;
}

.history-page .balance {
  display: flex;
  flex-direction: row;
  padding: 2% 10%;
  font-size: 1.3rem;
}

.history-page .balance .key {
  margin: auto auto auto 0%;
  display: flex;
}
.history-page .balance .value {
  display: flex;
  margin-left: auto;
  font-size: 1.8rem;
}

.history-page .item1 {
  color: var(--theme-medium-gray);
}

.history-page .item2 {
  color: var(--theme-red);
}

.container.logs {
  font-size: 1.3rem;
}

.container.logs .content {
  display: flex;
  flex-direction: row;
}
.container.logs .content .details-left {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.container.logs .content .details-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}
.container.logs .content .details-right .tx-id {
  color: var(--theme-medium-gray);
  font-size: 1.2rem;
}

.container.logs .content .button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.container.logs .content .status {
  margin-left: auto;
  font-size: 1.2rem;
}

.warning {
  color: orange;
}

.SUCCESS {
  color: var(--theme-green);
}

.danger {
  color: var(--theme-red);
}

.container.logs .timestamp {
  color: var(--theme-medium-gray);
  font-size: 1.2rem;
}

.bp3-dialog {
  background-color: white;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 4%;
  padding: 4%;
}

.date-range-input .bp3-input-group {
  flex-shrink: unset;
  width: 40vw;
}

.logs {
  overflow: scroll;
}

.h-100 {
  height: 100%;
}

.bp3-button.load {
  width: 100%;
  margin: 25px 0;
  background-image: unset;
  background-color: #31c2c4 !important ;
  color: #fff;
  border-radius: 5px;
  box-shadow: unset;
}

.opacity-0 {
  opacity: 0;
}

.d-none {
  display: none;
}

.chip {
  width: auto;
  padding: 4px 12px;
  font-size: 8px;
  border-radius: 25px;
}

.chip.danger {
  background-color: #f44336;
  color: #fff;
}

.align-center {
  align-items: center;
}

.m-0 {
  margin: 0;
}

.badge {
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 5px;
}

.badge.success {
  color: #fff;
  background-color: #3bc6c8;
}

.badge.failed {
  color: #fff;
  background-color: #f44336;
}


.badge.processing {
  background-color: #ffe928;
}

.list-history-card {
  padding: 12px 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 8px 2px #ddd;
  margin-bottom: 1em;
}

.wallet-icon {
  width: 15px;
  margin-right: 5px;
}

.ewa-balance {
  border-right: 1px solid #ddd;
  width: 100%;
  margin-right: 15px;
}

.balance-box h5 {
  margin: 6px 0 0;
}

.wallet-balance {
  width: 100%;
  padding-top: 5px;
}

.balance-box {
  justify-content: center;
  padding: 0;
}

.balance-card {
  width: 80%;
  margin: 2em auto auto auto;
  padding: 10px 15px;
  border-radius: 6px;
  box-shadow: 1px 1px 7px 1px #ddd;
}

.border-0 {
  border: 0;
}
