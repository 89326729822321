.choose-page{
    text-align: center;
    padding: 3rem 2em 10rem;

    .selfi-method{
        margin-top: 2em;
        width: 100%;
        padding: 12px;
        background-color: #52b5b8;
        background-image: unset;
        color: #fff;
        border: 0;

        svg{
            margin-right: 18px;
        }
    }

    h3{
        margin-bottom: .5em;
    }

    .text-info{
        color: #52b5b8;
    }

    .text-secondary{
        color: #a9a9a9;
    }

    .row-custom{
        display: flex;
        justify-content: space-between;
    }
    
    .btn-clock{
        text-align: left;
        box-shadow: 1px 1px 3px 2px #ddd;
        padding: 10px;
        margin-top: 1em;
        
        .icon{
            padding-top: 18px;
        }
    }

    .reminder-group {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 20px;

        .form-group {
            display: flex;
            flex: row;
            gap: 10px;

            * {
                margin: 0;
            }
        }
    }
}

.mt-2{
    margin-top: 1em;
}

.mb-3{
    margin-bottom: 2em;
}