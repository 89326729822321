.clock-out {
  width: 100%;
  position: relative;
  margin-bottom: 85px;

  .form-map {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    gap: 10px;
  
    label {
      font-size: 14px;
      color: var(--theme-font-legend);
    }
  
    textarea {
      resize: none;
      padding: 10px;
      border: #ddd 1px solid;
      border-radius: 3px;
      background-color: var(--theme-light);
      font-family: Poppins;
      &::-webkit-input-placeholder {
        font-family: FontAwesome, Poppins;
      }
    }
  
    .btn-icon {
      border-radius: 4px;
      border: var(--theme-green) solid 1px;
      background-color: white;
      color: var(--theme-font-button);
      text-align: center;
      max-width: max-content;
      padding: 5px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 5px;
    }
  }

  .container {
    padding: 10px 20px;
  }

  .work-duration {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;

    .title {
      color: var(--theme-font-legend);
      font-size: 14px;
    }

    .radio {
      display: none;

      &:checked {
        ~.card-work-duration {
          background-color: var(--theme-gray);
        }
      }
    }

    .card-work-duration {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: white;
      border: var(--theme-border) 1px solid;
      border-radius: 4px;
      padding: 20px;
      
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #4e4e4e;
        font-size: 15px;
        font-weight: 800;
      }
      
      .duration {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #838383;
        font-size: 15px;
      }
    }
  }

  .submit-btn {
    background-image: none;
    background-color: var(--theme-green);
    border: none;
    color: white;
    width: 100%;
    padding: 20px;
    text-align: center;

    &:disabled {
      background-color: #ddd;
    }
  }
}