.card-calendar {
  display: flex;
  width: 100%;
  padding: 1em;
  border: 0.5px #ddd solid;
  border-radius: 24px;
  flex-direction: row;
  gap: .6em;
  align-items: flex-start;
  margin: 0;

  &.Approved {
    border-color: hsl(120, 100%, 70%);
  }

  &.Rejected {
    border-color: hsl(0, 100%, 65%);
  }
  
  .icon-wrapper {
    padding: 0 10px;
    &.Approved {
      color: hsl(120, 100%, 40%);
    }

    &.Rejected {
      color: hsl(0, 100%, 60%);
    }
  }

  h3{
    margin-left: 3px;
  }

  table{
    margin-top: .4em;
    font-size: 12px;
    td{
      vertical-align: top;
      &.detail-data{
        text-transform: capitalize;
        padding-left: 1em;
      }
    }
  }

  .button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-end;
    flex-grow: 1;

    .btn {
      outline: none;
      border: none;
      border-radius: 8px;
      width: 80px;
      padding: 5px;
      &.approve {
        background-color: #01d587;
        color: rgb(56, 52, 52);
      }
      &.reject {
        background-color: #b36161;
        color: #fff;
      }
      &.info {
        background-color: #5994FF;
        color: #fff;
      }
    }
  }
}

.calendar-card{
  &.overtime{
    background-color: #83C3FF;
  } 

  &.presence{
    background-color: #01d587;
  }

  &.leave{
    background-color: #ff8e8e;
  }
  color: #fff;
  border-radius: 8px;
  min-width: 75px;
  height: 75px;
  text-align: center;

  .calendar-header{
    &.overtime{
      background-color: #5994FF;
    }

    &.presence{
      background-color: #29bd87;
    }

    &.leave{
      background-color: #eb6d6d;
    }
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 0.5em;
    font-size: 12px;
    text-transform: uppercase;
  }

  .calendar-body{
    font-size: 33px;
  }
}

.popup-calendar {
  
  .title {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;

    &.overtime{
      background-color: #83C3FF;
    } 
  
    &.presence{
      background-color: #01d587;
    }
  
    &.leave{
      background-color: #ff8e8e;
    }

    h3 {
      margin: 0;
    }
  }

  table {
    margin-top: 2em;
    td {
      padding-bottom: 6px;

      &.detail-data {
        text-transform: capitalize;
      }
    }
  }

  .documents {
    img {
      width: 40%;
    }
  }
}