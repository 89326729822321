.mb-1{
  margin-bottom: .5em;
}

.table-project{
  width: 100%;
}

.mt-0{
  margin-top: 0;
}

.mr-2{
  margin-right: 1em;
}

.ml-2{
  margin-left: 1em;
}

.btn-warning{
  background-color: #fc9722 !important;
  background-image: unset !important;
}

.h-auto{
  height: auto !important;
}

.w-50{
  width: 50%;
}

.text-right{
  text-align: right;
}

.mt-sm-1{
  margin-top: 8px;
}

.text-info{
  color: rgb(82 181 184);
}

.text-warning{
  color: #ff9800;
}

.submit-box{
  background-color: #fff;
  border-top: 1px solid #ddd;
  padding-top: 15px;

  button{
    padding: 12px;
  }
}

.member-group-list{
  padding: 0;
  margin: 0;
  
  li{
    padding: 10px 0 0;
    
    p{
      width: 100%;
    }
  }
}

.custom-select{
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.homepage-tracking{
  button:disabled{
    background-color: #ccc !important;
  }
}

.text-danger{
  color: rgb(247, 77, 39);
}

.btn-danger{
  background-color: rgb(242, 59, 59) !important;
  background-image: unset !important;
  color: #fff !important;
}

.bp3-toast-container{
  z-index: 99999999;
}

.bp3-toast.bp3-intent-danger.bp3-overlay-content.bp3-toast-enter-done{
  z-index: 999999999;
}

.bp3-portal > .bp3-overlay >  .bp3-overlay-backdrop > .bp3-dialog-container > .form-generate-kpi{
  z-index: unset !important;
}