.registration-step {
  display: flex;
  flex-direction: column;
  margin: 10% 10% 0% 10%;
}

.registration-step .bp3-button {
  border-radius: 1rem;
  padding: 1% 5%;
  background-color: var(--theme-green);
  box-shadow: none;
  color: white;
  background-image: none;
  margin: 5% auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
}

.registration-step .footer {
  width: 50%;
  margin: 2% auto;
}


.registration-step .footer {
  width: 50%;
  margin: 2% auto;
}

.registration-step .header{
  position: relative;
  box-shadow: unset;
  font-size: 14px;
}

.registration-step .container.hp-number .hp-field input{
  margin-top: 0;
}

.registration-step .container{
  padding: 0;
}