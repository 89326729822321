.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;
}

.login-page .card {
  margin: 2% 10%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5%;
}

.container.logo .title {
  text-align: center;
  font-weight: 900;
  letter-spacing: 0.3rem;
  color: var(--theme-green);
  padding: 2%;
}

.container.logo img {
  width: 20vw;
  margin-left: auto;
  margin-right: auto;
}

.container.fill-form {
  padding: 2%;
}

.container.fill-form .input-container {
  margin-bottom: 5%;
  color: var(--theme-medium-gray);
}

.container.fill-form input {
  background-color: var(--theme-light-gray);
  border: 0.005rem solid var(--theme-medium-gray);
  border-radius: 5px;
  text-align: center;
  width: 100%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.container.button {
  margin: 5% auto 10% auto;
}

.container.button .btn {
  font-family: Poppins;
  border-radius: 1rem;
  background-image: none;
  margin: auto;
  color: white;
  width: 100%;
  text-align: center;
}

.container.button .btn:enabled {
  background-color: var(--theme-pink);
  border: none;
  box-shadow: none;
}

.container.button a {
  color: var(--theme-purple);
  letter-spacing: 0.2rem;
  margin: 2% auto 5% auto;
}

/* .login-error-message {
  color: red;
} */

.container.enquiry {
  flex-direction: row;
  justify-content: space-between;
}

.container.enquiry .question {
  color: var(--theme-medium-gray);
}

.container.enquiry a {
  color: var(--theme-gray);
}

.container.enquiry svg {
  display: inline-flex;
  margin: auto 20%;
}

.bp3-button.bp3-minimal {
  background: var(--theme-light-gray);
  border-radius: 2em;
  margin: 10% auto;
}
