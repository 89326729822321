.approval {
  width: 100%;
  min-height: 90vh;
  position: relative;

  .tabs {
    position: fixed;
    width: 100%;
    padding-top: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      width: 49%;
      margin: auto;
      padding: 10px;
      text-align: center;
      border: none;
      border-bottom: 1px solid black;
      outline: none;
      background-color: #fff;

      &.active {
        background-color: hsl(0, 0%, 95%);
      }
    }
  }

  .list-view {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    padding-top: 55px;

    .action-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .choose-all {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        * {
          margin: 0;
        }
      }

      .btn {
        outline: none;
        border: none;
        border-radius: 8px;
        padding: 5px 10px;
        background-color: #01d587;
        color: rgb(56, 52, 52);
      }
    }

    .list {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }
  }
}

.popup-reject {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  .title {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    background-color: #ff8e8e;
  }

  .form-group {
    display: flex;
    flex-direction: column;

    label {
      font-size: .8em;
      color: #000;
    }
  
    textarea {
      width: 100%;
      height: 100px;
      resize: none;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 3px;
      background-color: var(--theme-light);
      font-family: Poppins;
      &::-webkit-input-placeholder {
        font-family: Poppins;
      }
    }
  }

  .btn {
    outline: none;
    border: none;
    border-radius: 8px;
    padding: 8px 10px;
    background-color: #01d587;
    color: #fff;
  }
}