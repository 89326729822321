.dashboard-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  background: white;
}

.dashboard-container .dashboard-header {
  background: white;
  position: absolute;
  top: 0;

  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding-left: 5vw;
  z-index: 9999;
}

.dashboard-container .dashboard-header .dashboard-title {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
  font-weight: bold;
  flex-grow: 4;
}

.dashboard-container .dashboard-header .dashboard-reload {
  margin-top: auto;
  margin-bottom: auto;
  flex-grow: 1;
}

.dashboard-container .dashboard-body {
  background: rgb(247, 250, 252);
  top: 10%;
  position: absolute;
  height: 80%;
  width: 100%;
  display: flex;
  overflow-y: scroll;
}

.dashboard-body.hide {
  top: 0;
  height: 100vh;
}

.dashboard-container .dashboard-footer {
  background: white;
  position: fixed;
  bottom: 0;

  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  z-index: 9999;
}

.dashboard-container .dashboard-footer .link {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 10px;
}

.dashboard-container .dashboard-footer .link:last-child {
  pointer-events: none;
  opacity: 0;
}

.dashboard-container .dashboard-footer .link.link-selected {
  background-color: var(--theme-light-gray);
}

.dashboard-container .dashboard-footer .link > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-container .dashboard-footer .link .nav-icon > span > svg {
  width: 4em;
}

.footer-container-icon {
  margin: 0 auto;
  text-align: center;
}

.nav-icon span {
  margin-bottom: 8px;
}
