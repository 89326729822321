@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";

.clock-in {
  width: 100%;
  position: relative;
  margin-bottom: 85px;

  .form-map {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    gap: 10px;
  
    label {
      font-size: 14px;
      color: var(--theme-font-legend);
    }
  
    textarea {
      resize: none;
      padding: 10px;
      border: #ddd 1px solid;
      border-radius: 3px;
      background-color: var(--theme-light);
      font-family: Poppins;
      &::-webkit-input-placeholder {
        font-family: FontAwesome, Poppins;
      }
    }
  
    .btn-icon {
      border-radius: 4px;
      border: var(--theme-green) solid 1px;
      background-color: white;
      color: var(--theme-font-button);
      text-align: center;
      max-width: max-content;
      padding: 5px;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 5px;
    }

    .error {
      color: #c14040;
      font-size: 10px;
      font-style: italic;
    }
  }

  .container {
    padding: 10px 20px;
    .reason {
      width: 100%;
      height: 100px;
      resize: none;
      padding: 10px;
      border: #ddd 1px solid;
      border-radius: 3px;
      background-color: var(--theme-light);
      font-family: Poppins;
      margin-bottom: 50px;
      &::-webkit-input-placeholder {
        font-family: Poppins;
      }
    }
  }

  .submit-btn {
    background-image: none;
    background-color: var(--theme-green);
    border: none;
    color: white;
    width: 100%;
    padding: 20px;
    text-align: center;

    &:disabled {
      background-color: #ddd;
    }
  }
}
