.body-content{
  padding: 2rem 1rem 10rem    ;
}

.flex-group{
  flex-wrap: row;
}

.project-desc{
  h4{
    margin-top: 0;
  }
}

.btn-info{
  background: #3bc6c8 !important;
  color: #fff !important;
}

.btn-lg{
  padding: 12px;
}

.data-info-wrap{
  display: flex;
  flex-wrap: wrap;
  .data-box{
    background-color: #ededed;
    width: 100%;
    padding: 10px;
    text-align: center;
    flex-grow: 1;
    width: 30%;

    h3{
      color: #3bc6c8;
    }

    small{
      color: #747474;
    }
  }
}

.card-custom{
  box-shadow: 0px 0px 7px 5px #ddd;
  border-radius: 5px;
  .card-custom-header{
    padding: 1em;
    border-bottom: 1px solid #ddd;
    h4{
      margin: 0;
    }
  }

  .card-custom-body{
    padding: 1em;

    .list-member{
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      li{
        margin: 10px 10px 0 0;
        color: #3bc6c8;
        font-weight: bold;

        &:first-child{
          margin-left: 0;
        }
      }
    }
  }

  .card-custom-footer{
    padding: 1em;
    border-top: 1px solid #ddd;
  }
}

.btn-warning-outline{
  background-color: white !important;
  background-image: unset !important;
  border: 1px solid #ff9800 !important;
  color: #ff9800 !important;
  box-shadow: unset !important;
}

.text-center{
  text-align: center;
}

.close-icon-modal{
  font-size: 2rem;
}

.history-list{
  overflow-y: scroll;
  .history-box{
    .history-header{
      background-color: #e8e8e8;
      padding: 5px 10px;
      h4{
        margin: 0;
      }
    }

    .history-body{
      ul{
        padding: 0;
        list-style: none;
        margin-top: 0;

        li{
          padding: 10px 0;
          border-bottom: 1px solid #DDD;
  
          &:last-child{
            border-bottom: 0;
          }
        }
      }

    }
  }
}